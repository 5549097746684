/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.css in this case)
require('../css/global.scss');
require('bootstrap');
require('luminous-lightbox');

import LazyLoad from 'vanilla-lazyload';

const $ = require('jquery');

const LuminousGallery = require('luminous-lightbox').LuminousGallery;

const galleryOpts = {
    'arrowNavigation': true
};
const luminousOpts = {
    'caption': function(element) {
        return element.children[0].title
    },
    'showCloseButton': true,
    'injectBasicStyles': true
};

new LuminousGallery(document.querySelectorAll(".artwork-lightbox"), galleryOpts, luminousOpts);

var myLazyLoad = new LazyLoad({
    elements_selector: ".lazy"
});

    $(window).on("load", function() {

        $("body a[href]").click(function(){

            var $anchor = $(this);

            // DON'T FADE FOR LINKS THAT OPEN IN NEW WINDOW
            if ( $anchor.attr('target') && $anchor.attr('target').indexOf('_blank') >=0 )
                return;

            // DON'T FADE FOR EMAIL
            if ( $anchor.attr('href').indexOf('mailto:') >=0 )
                return;

            // DON'T FADE FOR TELEPHONE LINKS
            if ( $anchor.attr('href').indexOf('tel:') >=0 )
                return;

            // DON'T FADE FOR LINKS TO ANCHOR TAGS
            if ( $anchor.attr('href').indexOf('#') >=0 )
                return;

            if ( $anchor.hasClass('artwork-lightbox'))
                return;


            $('.navbar-collapse').slideUp();

            // FADE OUT
            $("main").animate({ opacity: 0 }, 300, function() {
                $(".spinner").fadeIn(500);
            });

        });

    });

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
